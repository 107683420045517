import React, { useState } from 'react'
import './styles.css'
import nhlLeaders from '../../nhlLeaders.json'
import NhlLeadersCard from './nhlLeadersCard';

const NhlLeaders = () => {

    return (
        <div className='NhlLeadersWrapper'>
            <div className='NhlLeadersText'>
                <h4>NHL Leaders 2023/2024</h4>
            </div>
            <NhlLeadersCard title='goals' data={nhlLeaders.goals} />
            <NhlLeadersCard title='points' data={nhlLeaders.points} />
            <NhlLeadersCard title='gaa' data={nhlLeaders.GAA} />
            <NhlLeadersCard title='wins' data={nhlLeaders.wins} />

        </div>
    )
}

export default NhlLeaders