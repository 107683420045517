import React, { useRef, useState } from 'react';
import { Button, Container, Row, Spinner } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/navigation';
import './style.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import moment from 'moment';
import { cutText } from '../../helpers/getHelpers';

export default function News({ news, loading }) {

    return (
        <>
            <Container className='NewsWrapper'>
                {
                    loading ?
                        <div style={{ height: 450, display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Spinner animation="grow" style={{ backgroundColor: "#C6771F" }} />
                        </div>
                        :
                        <Swiper
                            slidesPerView={3.5}
                            spaceBetween={30}
                            navigation={true}
                            modules={[Navigation]}
                            className="newsSwiperContainer"
                            breakpoints={{
                                1250: {
                                    slidesPerView: 3.5,
                                },
                                900: {
                                    slidesPerView: 3,
                                },
                                600: {
                                    slidesPerView: 2,
                                },
                                450: {
                                    slidesPerView: 1.7,
                                },
                                0: {
                                    slidesPerView: 1,
                                }
                            }}
                        >
                            {
                                news?.map((item, index) => {
                                    return (
                                        <SwiperSlide>
                                            <div className='newsSwiperSlideContainer'>
                                                <div className='newsSwiperSlideHeader'>
                                                    <h2>News</h2>
                                                    <h3>#{index + 1}</h3>
                                                </div>
                                                <div className='newsSwiperSlideContent'>
                                                    <div className='newsSwiperSlideContentInfos'>
                                                        <p><b>Categorie</b> <br /> {item?.category}</p>
                                                        <p style={{ textAlign: 'right' }}><b>Published</b> <br /> {moment(item?.published).format('YYYY-MM-DD')}</p>
                                                    </div>
                                                    <div className='newsSwiperSlideContentImage'>
                                                        <img src={item?.enclosures[0].url} alt='image' />
                                                    </div>
                                                    <div className='newsSwiperSlideContentTitleDesc'>
                                                        <h6>{item?.title}</h6>
                                                        <p>{cutText(item?.description, 50, 220)}</p>
                                                    </div>
                                                </div>
                                                <div className='newsSwiperSlideFooter'>
                                                    <a href={item?.link} target='_blank'>
                                                        <Button className='newsSwiperSlideFooterButton'>
                                                            Read More
                                                        </Button>
                                                    </a>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }

                        </Swiper>
                }
            </Container>
        </>
    )
}
