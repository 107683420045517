import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
// import * as Yup from 'yup';
import { login, getCusotmers } from '../../../api'
import Cookies from 'universal-cookie'
import LoginModal from '../../loginmodal';
import backgrnd from '../../../assets/images/signup.webp'

export default function SignIn() {
    const [errorMessage, setErrorMessage] = useState()
    const [modalShow, setModalShow] = useState(false);
    const [users, setUsers] = useState([]);
    const cookie = new Cookies()


    const getUsers = () => {
        getCusotmers().then(response => {
            setUsers(response.data)
        })
    }

    useEffect(async () => {
        getUsers()
    }, [])

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: () => {
            const username = formik.values.username
            const password = formik.values.password;
            const func = login(username, password)
            func.then(async (response) => {
                if (response.success) {
                    // setLogInMessage('Your account is suspended, Please contact customer support');
                    // alert(response.data.message);
                    // setModalShow(true);
                    cookie.set('token', response.token, { path: '/' })
                    cookie.set('firstname', response.data.firstName, { path: '/' })
                    cookie.set('username', response.data.username, { path: '/' })
                    cookie.set('id', response.data._id, { path: '/' })
                    cookie.set('balance', response.data.balance, { path: '/' })
                    cookie.set('email', response.data.email, { path: '/' })
                    cookie.set('currency', response.data.currency, { path: '/' })
                    cookie.set('wallet', response.data.wallet, { path: '/' })
                    window.location.reload()
                } else {
                    setErrorMessage(response.message)
                }
            })
        }
    })


    return (
        <Container fluid style={{ padding: 0, height: '100%', overflow: "hidden" }}>
            <p style={{ color: 'red' }}>
                {errorMessage}
            </p>
            <Row style={{ padding: 0, height: '99.9vh', overflowY: 'hidden' }}>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} style={{ padding: 0, margin: 0 }}>
                    <img src={backgrnd} style={{ width: "100%", height: "60vh" }} />
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} style={{ padding: '0px 32px' }}>
                    <div style={{ padding: 0 }}>
                        <div style={{ marginBottom: "8px", textAlign: 'center' }}>
                            <h4>Sign In</h4>
                        </div>
                        <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                            {/* <Form.Label><h5 className='usernameTitle'>Username</h5></Form.Label> */}
                            <Form.Control
                                className='textInput'
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.username}
                                type="text"
                                name="username"
                                placeholder="username"
                            />

                            {/* <Form.Label><h5 className='passwordTitle'>Password</h5></Form.Label> */}
                            <Form.Control
                                className='textInput'
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                type="password"
                                name="password"
                                placeholder="password"
                            />
                            <div className='ModalFooterContainer' >
                                <Button type="submit" className='ModalFooterSubmit' >
                                    Sign In
                                </Button>
                            </div>
                        </Form>
                        <LoginModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </div>
                </Col>

            </Row>
        </Container>
    )
}
