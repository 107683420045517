import React, { useEffect, useState } from 'react'
import './styles.css'
import Cookies from 'universal-cookie'
import moment from 'moment/moment';
import { getTransactions } from '../../../../api';
import { Badge, Col, Row, Table } from 'react-bootstrap';
import transaction from '../../../../assets/images/transaction.jpg'

function Transactions() {
    const cookie = new Cookies()
    const id = cookie.get('id');
    const [transactions, setTransactions] = useState([]);

    useEffect(
        () => {
            getTransactions(id).then(async res => {
                // const transactionResult = [];
                // res.data.forEach(element => {
                //     if (element.status === 0) {
                //         transactionResult.push(element)
                //     }
                // });

                setTransactions(res.data.reverse())
            }).catch(error => console.log(error));
        },
        [])



    return (
        <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: 0 }}>
                <div className='modalHeader'>
                    <h2>Transactions</h2>
                </div>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className='transactionsWrapper' style={{ marginTop: "32px" }}>
                    <div className='transactionsContainer'>
                        <Table responsive="sm">
                            <thead className='transactionTableHead'>
                                <tr>
                                    <th><b>From</b></th>
                                    <th><b>To</b></th>
                                    <th><b>Amount</b></th>
                                    <th><b>Currency</b></th>
                                    {/* <th><b>Date</b></th> */}
                                    <th><b>Status</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    transactions?.map(item => (
                                        <>
                                            <tr key={item._id}>
                                                <td align='center'><p>{cookie.get('username')}</p></td>

                                                <td align='center'><p>{item.receiver}</p></td>

                                                <td align='center'><p>{item.amount}</p></td>
                                                <td align='center'><p>{item.sender.currency}</p></td>
                                                {/* <td align='center'><p>{moment(item.createAt).format('L')}</p></td> */}

                                                <td align='center'>
                                                    {
                                                        item.status === 0 ?
                                                            <>
                                                                <Badge bg="warning" text="dark">
                                                                    Pending
                                                                </Badge>
                                                            </>
                                                            :
                                                            <>
                                                                <Badge bg="success">Success</Badge>
                                                            </>
                                                    }
                                                </td>
                                            </tr>

                                        </>

                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Col>
            {/* <Col xxl={4} xl={4} lg={4} className='transactionImageContainer'>
                <img src={transaction} style={{ width: '100%', height: '100%' }} />
            </Col> */}
        </Row>
    )
}

export default Transactions