import { React, useState } from 'react'
import { Button } from 'react-bootstrap'
import './style.css';
import Modale from '../modal'

export default function Buttons(props) {
    const [show, setShow] = useState(false);
    function getTextColor() {
        switch (props.kind) {
            case "primary":
                return "#231123";
            case "orange":
                return "#fff";
            case "transparent":
                return "#2B2C2D";
            case "transparent2":
                return "#fff";
            default:
                break;
        }
    }

    function getBackgroundColor() {
        switch (props.kind) {
            case "primary":
                return "#EFF7FF";
            case "orange":
                return "#E79C25"
            case "transparent":
                return "transparent";
            case "transparent2":
                return "transparent";
            default:
                break;
        }
    }

    function getBorderColor() {
        switch (props.kind) {
            case "primary":
                return "#EFF7FF";
            case "orange":
                return "#E79C25";
            case "transparent":
                return "transparent";
            case "transparent2":
                return "transparent";
            default:
                break;
        }
    }
    return (
        <>
            <Button
                style={{
                    backgroundColor: getBackgroundColor(),
                    color: getTextColor(),
                    borderColor: getBorderColor(),
                }}
                className='Buttons'
                onClick={() => setShow(true)}
            >
                {props.text}
            </ Button>

            <Modale
                show={show}
                onHide={() => setShow(false)}
                setShow={setShow}
                signin={props.signin}
                signup={props.signup}
                message={props.message}
                profile={props.profile}
                deposite={props.deposite}
                withdraw={props.withdraw}
                transaction={props.transaction}
                history={props.history}
                contact={props.contact}
            />
        </>
    )
}
