import React from 'react'
import './styles.css'
import { Container, Spinner } from 'react-bootstrap'
import YouTubeEmbed from '../youtubeEmbed'

const Highlights = ({ highlights, loading }) => {
    return (
        <Container className='highlightsWrapper'>
            <div className='highlightsTitleContainer'>
                <h2>Sports Highlights</h2>
            </div>
            <div>
                {loading ?
                    <div style={{ height: 450, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Spinner animation="grow" style={{ backgroundColor: "#C6771F" }} />
                    </div>
                    :
                    <div className='highlightsContentContainer'>
                        {
                            highlights?.map((highlight) => {
                                return (
                                    <div className='highlightsContent'>
                                        <YouTubeEmbed youtubeUrl={highlight?.strVideo} />
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </Container>
    )
}

export default Highlights