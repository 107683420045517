import React, { useState } from 'react'
import './styles.css'
import { leagues } from '../usaLeagues/data'
import games from './games.json'
import moment from 'moment'

const Games = () => {
    const [gameLeagueSelected, setGameLeagueSelected] = useState('NHL')

    return (
        <div className='GamesWrapper'>
            <div className='GamesText'>
                <h4>Games</h4>
            </div>
            <div className='GamesHeaderContainer'>
                {
                    leagues?.map((league) => {
                        return (
                            <div className={league?.leagueName === gameLeagueSelected ? 'GamesHeaderCardSelected' : 'GamesHeaderCard'} onClick={() => setGameLeagueSelected(league?.leagueName)}>
                                <img src={league?.icon} width={20} height={20} />
                                <p>{league?.leagueName}</p>
                            </div>
                        )
                    })
                }
            </div>
            <div className='GamesContentContainer'>
                {
                    games[gameLeagueSelected]?.games ?
                        <>
                            {
                                games[gameLeagueSelected]?.games?.map((game, index) => {
                                    return (
                                        <>
                                            <div className='GamesContent'>
                                                <h3>{moment().add(game?.date, "days").format("dddd DD/MM")}</h3>
                                            </div>
                                            <div className='GamesContentCardsContainer'>
                                                {
                                                    game?.games?.map((match, index) => {
                                                        return (
                                                            <div className='GamesContentCard'>
                                                                <div className='GamesContentCardTeamsContainer'>
                                                                    <div className='GamesContentCardHomeContainer'>
                                                                        <img src={match?.homeTeam?.logo} width={50} height={50} />
                                                                        <p>{match?.homeTeam?.abbrev}</p>
                                                                        {
                                                                            match?.homeTeam?.score &&
                                                                            <p className='GamesContentCardScore'>{match?.homeTeam?.score}</p>
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <p><b>VS</b></p>
                                                                    </div>
                                                                    <div className='GamesContentCardAwayContainer'>
                                                                        <img src={match?.awayTeam?.logo} width={50} height={50} />
                                                                        <p>{match?.awayTeam?.abbrev}</p>
                                                                        {
                                                                            match?.awayTeam?.score &&
                                                                            <p className='GamesContentCardScore'>{match?.awayTeam?.score}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='GamesContentCardStartTime'>
                                                                    <p>
                                                                        {
                                                                            (!match?.homeTeam?.score && !match?.awayTeam?.score) &&
                                                                            <p>{moment(match?.startTimeUTC).format('HH:mm a')}</p>
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className='GamesContentCardTicketLink'>
                                                                    <a href={match?.ticketsLink ? match?.ticketsLink : match?.highlights} target='_blank'>
                                                                        <p>{match?.ticketsLink ? "Get Tickets" :
                                                                            match?.highlights ? "View Highlights" : ""}</p>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </>
                        :
                        <>
                            <h3 className='noGamesMessage'>
                                {games[gameLeagueSelected]?.message}
                            </h3>
                        </>

                }
            </div>
        </div>
    )
}

export default Games