import React, { useState } from 'react'
import './styles.css'
import Cookies from 'universal-cookie'
import { Button, Col, Container, Row } from 'react-bootstrap'
import deposit from '../../../../assets/images/deposit.jpg'

function Deposit() {
    const cookie = new Cookies()

    return (
        <Container fluid style={{ padding: 0 }}>
            <Row xxl={12} xl={12} lg={12}>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: 0 }}>
                    <div className='modalHeader'>
                        <h2>Deposit</h2>
                    </div>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                    <div className='depositeWrapper'>
                        <div className='depositeContainer'>
                            <h5 className='depositeTitle'>Wallet Adress:</h5>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <input
                                    className='inputDisabled'
                                    disabled
                                    size="small"
                                    id="outlined-basic"
                                    value={cookie.get('wallet')}
                                    variant="outlined" />

                                <Button
                                    variant="primary"
                                    onClick={() => navigator.clipboard.writeText(cookie.get('wallet'))}
                                    className="copyButton">
                                    Copy Wallet
                                </Button>
                            </div>
                        </div>
                    </div>
                </Col>
                {/* <Col xxl={5} xl={5} lg={5} className='depositImageContainer'>
                    <img src={deposit} style={{ width: '100%', height: '100%' }} />
                </Col> */}
            </Row>
        </Container>
    )
}

export default Deposit