import React, { useEffect, useState } from 'react'
import './styles.css'
import Cookies from 'universal-cookie'
import { useFormik } from 'formik'
import { reduce, createTransaction, getUserById } from '../../../../api'
import { Col, Container, Row, Button, Form, InputGroup } from 'react-bootstrap'
import withdraw from '../../../../assets/images/withdraw.jpg'


function WithDraw() {
    const cookie = new Cookies()
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [amountValue, setAmountValue] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);

    const [user, setUser] = useState([])


    useEffect(
        () => {
            getUserById(cookie.get('id')).then(res => {
                setUser(res.data)
            }).catch(error => console.log(error));
        },
        [])

    const handleAmountChange = (e) => {
        setAmountValue(e.target.value);
    };

    const formik = useFormik({
        initialValues: {
            wallet: '',
            receiver: '',
            amount: ''
        },
        onSubmit: () => {
            const amount = amountValue;
            const receiver = formik.values.receiver;

            const reload = () => {
                window.location.reload()
            }


            const data = {
                sender: user._id,
                receiver: formik.values.receiver,
                amount: amountValue,
            }

            if ((receiver === "" && amount === "") || amount <= 0 || receiver === "" || amount === "") {
                setErrorMessage("Empty Fields or Negative Value!!");

            } else {
                if (amount <= user?.balance) {
                    if (!buttonClicked) {
                        setButtonClicked(true)
                        const func = createTransaction(data)
                        func.then((response) => {
                            if (response.success) {
                                setMessage("Your order has been processed, awaiting approval!! Kindly check transaction page.");
                                reduce(user.wallet, amount)
                                setTimeout(reload, 3000);
                            } else {
                                alert("error")
                            }
                        })
                    }
                } else {
                    setErrorMessage("Insufficent Balance!!");
                }
            }



        }
    })

    return (
        // <Container>
        <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: 0 }}>
                <div className='modalHeader'>
                    <h2>Withdraw</h2>
                </div>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: 16 }}>
                <div className='widthDrawWrapper'>
                    <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                        {
                            message ?
                                <div className='successMessageContainer'>
                                    <p>{message}</p>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                        {
                            errorMessage ?
                                <div className='errorMessageContainer' id="message">
                                    <p>{errorMessage}</p>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className='formWrapper'>
                        <Form onSubmit={formik.handleSubmit} className="formContainer" >
                            <Form.Group className="mb-3" controlId="formBasicFirstName">
                                <Form.Label><h5 className='withdrawLabel'>Wallet Adress</h5></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={user?.wallet}
                                    disabled
                                    className='withdrawInputD'
                                />
                            </Form.Group>
                            <div style={{ display: "flex", gap: 16 }}>

                                <Form.Group className="mb-3" controlId="username" style={{ width: "50%" }}>
                                    <Form.Label><h5 className='withdrawLabel'>Receiver</h5></Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="123"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.receiver || ''}
                                        name="receiver"
                                        id="receiver"
                                        className='withdrawInput'
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="username" style={{ width: "50%" }}>
                                    <Form.Label><h5 className='withdrawLabel'>Amount</h5></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            required
                                            type="number"
                                            placeholder="123"
                                            onChange={handleAmountChange}
                                            value={amountValue}
                                            name="amount"
                                            id="amount"
                                            className='withdrawInput'
                                        />
                                        <Button onClick={() => setAmountValue(user.balance)} className="maxButton">
                                            MAX
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            <div className='withdrawSendContainer'>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="withdrawSubmitButton">Withdraw</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Col>
        </Row>

        // </Container>
    )
}

export default WithDraw