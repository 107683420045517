import React, { useState } from 'react'
import './styles.css'

const NhlLeadersCard = ({ title, data }) => {
    const [highlightedLeader, setHighlightedLeader] = useState(data[0]);
    const calculateWidth = (index) => {
        const widths = ['85%', '80%', '70%', '65%', '60%']; // Define your widths here
        return widths[index] || '80%'; // Default to '70%' if index is out of bounds
    };

    return (
        <>
            {/* Details section that updates on hover */}
            <div className='NhlLeadersDetails'>
                <div className='NhlLeadersImgNameDetails'>
                    <img
                        width={60}
                        height={60}
                        style={{ borderRadius: "100px" }}
                        src={highlightedLeader?.headshot}
                        alt={`${highlightedLeader?.firstName?.default} ${highlightedLeader?.lastName?.default}`}
                    />
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <p style={{ color: "#234e88", textTransform: "capitalize" }}><b>{title}</b></p>
                        <p><b>{highlightedLeader.firstName?.default} {highlightedLeader.lastName?.default}</b></p>
                        <div style={{ display: "flex", alignItems: 'center' }}>
                            <img src={highlightedLeader?.teamLogo} width={30} height={30} alt={`${highlightedLeader?.firstName?.default} ${highlightedLeader?.lastName?.default}card`} />
                            <p>{highlightedLeader.teamAbbrev}</p>
                        </div>
                    </div>
                </div>
                <div className='NhlLeadersValueContainer'>
                    <h1>{highlightedLeader.value}</h1>
                    <h6 style={{ textTransform: "capitalize" }}> {title}</h6>
                </div>
            </div>

            {/* List that triggers update on hover */}
            <div className='NhlLeadersContentContainer'>
                {data.map((leader, index) => (
                    <div
                        className='NhlLeadersContent'
                        onMouseEnter={() => setHighlightedLeader(leader)} // Update details on hover
                        key={index}
                    >
                        <div
                            style={{
                                width: calculateWidth(index),
                            }}
                            className='NhlLeadersTextContainer'
                        >
                            <p>{index + 1}.</p>
                            <p style={{ marginLeft: "8px" }}>
                                {leader?.firstName?.default + " " + leader?.lastName?.default}
                            </p>
                        </div>
                        <div style={{ paddingRight: "16px" }}>
                            <p>{leader?.value}</p>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default NhlLeadersCard