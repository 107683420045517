import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import * as Yup from 'yup';
import { register } from '../../../api';
import ContactModal from '../../contactmodal';
import '../style.css';
import backgrnd from '../../../assets/images/signinImage.png'


export default function SignUp({ SupportModalShow, setSupportModalShow, Close }) {
    const [startDate, setStartDate] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState()


    const [phoneValue, setPhoneValue] = useState()


    const validationSchema = Yup.object({
        firstname: Yup
            .string('first name')
            .required('First name is required'),
        lastname: Yup
            .string('last name')
            .required('Last name is required'),
        username: Yup
            .string('User name')
            .required('Username is required'),
        password: Yup
            .string('password')
            .required('Password is required'),
    })
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            username: '',
            password: '',
            birthdate: startDate,

        },
        validationSchema: validationSchema,
        onSubmit: () => {

            const data = {
                firstName: formik.values.firstname,
                lastName: formik.values.lastname,
                email: formik.values.email,
                phone: phoneValue,
                username: formik.values.username,
                password: formik.values.password,
                birthdate: startDate,

            }

            const func = register(data)

            func.then(async (response) => {
                if (response.success) {
                    setErrorMessage(response.data.message)
                } else {
                    setErrorMessage('User already token')
                }
            })

        }
    })
    return (
        <Container fluid style={{ padding: 0 }}>
            {SupportModalShow ? null
                : errorMessage ?
                    <>
                        <div style={{ padding: 16, backgroundColor: '#FFECCC', marginBottom: 32, marginTop: 24, borderRadius: 0 }}>
                            <p style={{ color: errorMessage ? 'red' : 'green' }}>{errorMessage}</p>
                            <Button
                                onClick={() => setSupportModalShow(true)}
                                style={{
                                    borderRadius: '0px',
                                    backgroundColor: '#231123',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    border: 'none'
                                }}>Contact Us</Button>
                        </div>
                    </>
                    : null
            }

            {
                SupportModalShow ?
                    <>
                        <ContactModal Close={Close} setSupportModalShow={setSupportModalShow} />
                    </>
                    :
                    <>
                        <Row className='rowCont' style={{ padding: 0, margin: 0, height: '99.9vh', overflowY: 'hidden' }}>
                            {/* <Col xxl={12} xl={12} lg={12} md={12} sm={12} style={{ padding: 0, margin: 0 }}>
                                <img src={backgrnd} style={{ width: "100%", height: "50vh" }} />
                            </Col> */}
                            <Col xxl={5} xl={5} lg={5} md={5} sm={12} style={{ height: "100%", padding: 0 }}>
                                <img src={backgrnd} style={{ height: '100vh', width: '95%' }} />
                            </Col>
                            <Col xxl={7} xl={7} lg={7} md={7} sm={12}>
                                <div style={{ padding: 0, display: "flex", alignItems: "center", justifyContent: 'center', flexDirection: "column", width: '100%', height: '100%' }}>
                                    <div className='nameTitle2'>
                                        <h4 style={{ textAlign: "center", marginBottom: '8px' }}>Sign Up</h4>
                                    </div>
                                    <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ width: '100%' }} className='mb-0'>
                                                {/* <Form.Label><h5 className='signupinputLabel' style={{ margin: 0 }}>First Name</h5></Form.Label> */}
                                                <Form.Control
                                                    className='textInput'
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.firstname}
                                                    type="text"
                                                    name="firstname"
                                                    placeholder="first name"
                                                />
                                            </div>
                                            <div style={{ width: '100%', marginLeft: 8 }} className='mb-0'>
                                                {/* <Form.Label><h5 className='signupinputLabel' style={{ margin: 0 }}>Last Name</h5></Form.Label> */}
                                                <Form.Control
                                                    className='textInput'
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.lastname}
                                                    type="text"
                                                    name="lastname"
                                                    placeholder="last name"
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ width: '50%' }} className='mb-0'>
                                                {/* <Form.Label><h5 className='signupinputLabel' style={{ margin: 0 }}>Email Adress</h5></Form.Label> */}
                                                <Form.Control
                                                    className='textInput'
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                    type="email"
                                                    name="email"
                                                    placeholder="email"
                                                />
                                            </div>
                                            <div style={{ width: '50%', marginLeft: 8 }} className='mb-0'>
                                                <Form.Group className="mb-0" controlId="formBasicPhone" style={{ marginRight: 0 }}>
                                                    {/* <Form.Label><h5 className='signupinputLabel' style={{ margin: 0 }}>Phone Number</h5></Form.Label> */}
                                                    <PhoneInput
                                                        style={{ height: 40 }}
                                                        className='phoneNumber'
                                                        value={phoneValue}
                                                        onChange={setPhoneValue}
                                                        name="phone"
                                                        placeholder="phone number"
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex' }}>

                                            <div style={{ width: '100%' }} className='mb-0'>
                                                {/* <Form.Label><h5 className='signupinputLabel' style={{ margin: 0 }}>Username</h5></Form.Label> */}
                                                <Form.Control
                                                    className='textInput'
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.username}
                                                    type="text"
                                                    placeholder="username"
                                                    name="username" />
                                            </div>
                                            <div style={{ width: '100%', marginLeft: 8 }} className='mb-0'>
                                                {/* <Form.Label><h5 className='signupinputLabel' style={{ margin: 0 }}>Password</h5></Form.Label> */}
                                                <Form.Control
                                                    className='textInput'
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.password}
                                                    type="password"
                                                    name="password"
                                                    placeholder="password"
                                                />
                                            </div>
                                        </div>

                                        <div className='mb-4'>
                                            <Form.Group controlId="formBasicBirthDate">
                                                <Form.Label><h5 className='signupinputLabel' style={{ margin: 0 }}>Date Of Birth:</h5></Form.Label>
                                                <DatePicker
                                                    className='date'
                                                    selected={startDate}
                                                    name='birthdate'
                                                    onChange={(date) => setStartDate(date)}
                                                    value={startDate}
                                                />
                                            </Form.Group>
                                        </div>

                                        <Button
                                            type="submit"
                                            className='ModalFooterSubmit'
                                        >
                                            Sign Up
                                        </Button>
                                    </Form>
                                </div>
                            </Col>
                            {/* <Col xxl={4} xl={4} lg={4} md={4} sm={12}>
                                <div className='SwitchContainer'>
                                    <div className='SwitchText3'>
                                        Already have an account?
                                    </div>
                                    <div className='SwitchText4'>
                                        Unlock premium content and personalized experiences – Sign in to get started!
                                    </div>
                                    <Buttons text='sign in' kind="primary" signin={true} />
                                </div>
                            </Col> */}
                        </Row>
                    </>
            }

        </Container >
    )
}
