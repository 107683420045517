import React from 'react'
import './styles.css'
import rankings from '../../nbaRanking.json'
import { sortArrayAsc } from '../../helpers/getHelpers'

const NbaRankings = () => {
    return (
        <div className='nbaRankingWrapper'>
            <div className='nbaRankingText'>
                <h4>NBA Rankings 2023/2024</h4>
            </div>
            <div className='nbaRankingContent'>
                {
                    rankings?.standingGroups.map((ranking) => {
                        return (
                            <div className='nbaRankingContainer'>
                                <h5>{ranking?.conference}</h5>
                                <div className='nbaRankingGamesContainer'>
                                    {
                                        sortArrayAsc(ranking?.teams).map((team) => {
                                            return (
                                                <div className='nbaRankingGames'>
                                                    <div className='nbaRankingGamesRank'>
                                                        <p>{team?.standings?.confRank}</p>
                                                    </div>
                                                    <div className='nbaRankingGamesImageContainer'>
                                                        <img src={`https://cdn.nba.com/logos/nba/${team?.profile?.id}/primary/L/logo.svg`} width={40} height={40} />
                                                    </div>
                                                    <div className='nbaRankingGamesNameContainer'>
                                                        <p>{team?.profile?.cityEn + " " + team?.profile?.nameEn}</p>
                                                    </div>
                                                    <div className='nbaRankingGamesWinLossContainer'>
                                                        <p>W/L: <b>{team?.standings?.wins}</b>/{team?.standings?.losses}</p>
                                                    </div>
                                                    <div className='nbaRankingGamesLast10Container'>
                                                        <p>Last 10: {team?.standings?.last10}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default NbaRankings